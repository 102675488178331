<template>
  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} weekly menus</span>
      <span v-else class="text-truncate">Found {{meta.total}} weekly menus that match '{{query}}'</span>
      <div class="d-flex ml-auto">
        <select
          v-model="publishedOnly"
          data-test="select-weekly-menu-filter"
          class="form-control custom-select w-auto"
          @change="refresh">
          <option :value="false">Show all weeks</option>
          <option value="published">Published only</option>
        </select>
      </div>
    </div>
    <div class="row">

      <div class="col">
        <div class="card">
          <dimmer :active="downloading">
            <div class="table-responsive">
              <table data-test="table-weekly-menus" class="table table-hover table-outline table-vcenter text-nowrap card-table">
                <thead>
                  <tr>
                    <th class="w-1"></th>
                    <th>Week</th>
                    <th class="text-center">Recipes</th>
                    <th class="text-center">Published</th>
                    <th class="w-1">Last updated</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-center">
                      <span
                        class="avatar"
                        :style="`background-image: url(${identicon(weekName(item.startDate))});`"></span>
                    </td>
                    <td>
                      <router-link
                        v-if="can(uiPermissions.MENU_SCHEDULE_VIEW)"
                        :data-test="'link-weekly-menu-' + item.id"
                        :to="`/weekly-menus/${item.readableStartDate}`"
                        data-cy="link-weekly-menu">
                        {{weekName(item.startDate)}}
                      </router-link>
                      <span v-else>{{weekName(item.startDate)}}</span>
                    </td>
                    <td class="text-center">
                      {{item.recipes}}
                    </td>
                    <td class="text-center">
                      <i v-if="item.published" class="fe fe-check"></i>
                    </td>
                    <td>
                      {{moment.utc(item.updatedAt).local().fromNow()}}
                    </td>
                    <td class="text-center">
                      <div
                        v-if="can([uiPermissions.MENU_SCHEDULE_VIEW, uiPermissions.MENU_INGREDIENTS_EXPORT, uiPermissions.MENU_CLONE_INGREDIENTS_EXPORT])"
                        class="item-action dropdown">
                        <a
                          tabindex="0"
                          data-toggle="dropdown"
                          data-test="link-dropdown"
                          class="icon"><i class="fe fe-more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <router-link
                            v-if="can(uiPermissions.MENU_SCHEDULE_VIEW)"
                            :to="`/weekly-menus/${item.readableStartDate}`"
                            :data-test="'link-edit-' + item.id"
                            data-cy="link-edit"
                            class="dropdown-item">
                            <i class="dropdown-icon fe fe-edit"></i> Edit
                          </router-link>
                          <button
                            v-if="can(uiPermissions.MENU_INGREDIENTS_EXPORT)"
                            type="button"
                            data-test="btn-export-recipe-items"
                            class="dropdown-item"
                            @click="exportIngredients(item)">
                            <i class="dropdown-icon fe fe-download"></i> Export recipe items
                          </button>
                          <button
                            v-if="can(uiPermissions.MENU_CLONE_INGREDIENTS_EXPORT)"
                            type="button"
                            data-test="btn-export-clone-recipe-items"
                            class="dropdown-item"
                            @click="exportCloneIngredients(item)">
                            <i class="dropdown-icon fe fe-download"></i> Export clone recipe items
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="items.length === 0">
                    <td colspan="9">There are no results matching your criteria.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </dimmer>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import Pagination from '@/components/Pagination.vue';
import download from '@/mixins/download';
import list from '@/mixins/list';
import {weeklyMenus, weeklyMenusBasic} from '@/services';
import moment from 'moment-timezone';

export default {

  components: {

    Pagination,
  },
  mixins: [

    download,
    list,
  ],
  data() {
    return {

      publishedOnly: false,
    };
  },
  methods: {

    async exportIngredients(item) {
      this.download(await weeklyMenus.exportIngredientsById(item.id));
    },

    async exportCloneIngredients(item) {
      this.download(await weeklyMenus.exportCloneIngredientsById(item.id));
    },

    fetchData(page) {
      return weeklyMenusBasic.getByParameters({

        column: 'startDate',
        direction: 'desc',
        limit: this.limit,
        page,
        query: this.query,
        status: this.publishedOnly ? 'published' : ['scheduled', 'published'],
      });
    },
    transformData(result) {
      const mappedItems = result.items.map(item => ({
        ...item,
        startDate: moment(item.startDate),
        readableStartDate: item.startDate,
      }));
      return {...result, items: mappedItems};
    },
  },
};

</script>
